
import { Component, Vue } from "vue-property-decorator";
import CourseSignUpIntro from "@/components/Academy/CourseSignUp/CourseSignUpIntro.vue";
import CourseSignUpForm from "@/components/Academy/CourseSignUp/CourseSignUpForm.vue";
import CourseSignUpPre from "@/components/Academy/CourseSignUp/CourseSignUpPre.vue";
import CourseSignUpFinish from "@/components/Academy/CourseSignUp/CourseSignUpFinish.vue";

// declare type sessionInfo = {name: string; activityStartTime: string; activityEndTime: string; id: number;};
declare type courseDetail = {
  period: string;
  detailName: string;
  teacher?: string;
};

declare type course = {
  academyCourseId: number;
  academySessionType: number;
  activitySessionLocation: string;
  academySessionId: number;
  activitySessionStartTime: string;
  academySessionCourseName: string;
  activitySessionId: number;
  courseDetail: courseDetail[];
};

@Component({
  components: {
    CourseSignUpIntro,
    CourseSignUpForm,
    CourseSignUpPre,
    CourseSignUpFinish,
  },
})
export default class CourseSignUp extends Vue {
  //data
  introVisible = true;
  formVisible = false;
  finishVisible = false;
  preVisible = false;
  isLoading = false;
  viewWidth = 0; //null
  // dataFromChild:sessionInfo = {
  //   name: "",
  //   activityStartTime: "",
  //   activityEndTime: "",
  //   id: 0
  // };
  dataFromChild: course[] = [];
  dataFromChild2 = "";
  linkToParent = "";

  //hook
  mounted(): void {
    this.viewWidth = window.innerWidth;
    window.onresize = () => {
      this.viewWidth = window.innerWidth;
    };
  }
  //methods
  next(): void {
    this.introVisible = false;
    this.formVisible = true;
    this.preVisible = false;
    this.finishVisible = false;
    if (this.viewWidth > 400) {
      document.documentElement.scrollTop = 300;
    } else {
      document.documentElement.scrollTop = 0;
    }
  }
  send(): void {
    this.isLoading = true;
    setTimeout(() => {
      if (this.viewWidth > 400) {
        document.documentElement.scrollTop = 300;
      } else {
        document.documentElement.scrollTop = 0;
      }
      this.introVisible = false;
      this.formVisible = false;
      this.preVisible = true;
      this.finishVisible = false;
    }, 1000);
  }
  nextPre(): void {
    this.introVisible = false;
    this.formVisible = false;
    this.preVisible = false;
    this.finishVisible = true;
    if (this.viewWidth > 400) {
      document.documentElement.scrollTop = 300;
    } else {
      document.documentElement.scrollTop = 0;
    }
  }
  back(): void {
    this.introVisible = true;
    this.formVisible = false;
    this.preVisible = false;
    this.finishVisible = false;
    document.documentElement.scrollTop = 0;
  }
  // getChildData(data: {sessionInfo:sessionInfo, selectedClassRoom:string; tableLink:string}): void {
  getChildData(data: {
    sessionInfo: course[];
    selectedClassRoom: string;
  }): void {
    this.dataFromChild = data.sessionInfo;
    this.dataFromChild2 = data.selectedClassRoom;
    // this.linkToParent = data.tableLink;
  }
}
