
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CourseSignUpPre extends Vue {
  @Prop() public nextPre!: any;
  @Prop() public next!: any;
  @Prop() public linkToParent!: string;
}
