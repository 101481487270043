
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import tigermaster from "fdtigermaster-client-sdk";

import Loading from "@/components/Utility/Loading.vue";

import { format, toDate, parseISO, getDay } from "date-fns";

import {
  validateTWPhoneNum,
  validateEmail,
  validateAge,
} from "@/models/Validator/Validator";

import Calendar from "@/components/Academy/Utility/Calendar.vue";

// declare type courseDetail = {
//   detailName: string;
//   period: string;
//   teacher: string;
// };

// declare type course = {
//   academySessionId: number;
//   courseDate: string;
//   courseDetail: courseDetail[];
//   courseName: string;
//   id: number;
//   totalHour: number;
// };

declare type courseDetail = {
  period: string;
  detailName: string;
  teacher?: string;
};

declare type course = {
  academyCourseId: number;
  academySessionType: number;
  activitySessionLocation: string;
  academySessionId: number;
  activitySessionStartTime: string;
  academySessionCourseName: string;
  activitySessionId: number;
  courseDetail: courseDetail[];
};

declare type activitySession = {
  activityEndTime: string;
  activityStartTime: string;
  createBy: string;
  createDate: string;
  enrollEndDate: string;
  enrollStartDate: string;
  id: number;
  location: string;
  name: string;
  numberOfParticipants: number;
  quota: number;
  status: number;
  type: number;
  updateBy: string;
  updateDate: string;
};

declare type academy = {
  academyCourses: course[];
  activitySession: activitySession;
  classroom: string;
  createBy: string;
  createDate: string;
  id: number;
  telephone: string;
  timetableLink: string;
  type: number;
  updateBy: string;
  updateDate: string;
};

@Component({
  components: {
    Loading,
    Calendar,
  },
})
export default class CourseSignUpForm extends Vue {
  // props
  // @Prop() public send!: any;
  @Prop() public nextPre!: any;
  @Prop() public back!: any;

  //data
  getCourseList: course[] = [];
  //Administrative area.json
  postData = require("@/assets/post.json");
  //原始資料academy_session
  // getCourseData: academy[] = [];
  // getClassroomList: {
  //   type: number;
  //   timetableLink: string;
  //   activitySession: { status: number };
  // }[] = []; //某某校區包含密集0和假日1
  // classroomWeekday: {
  //   type: number;
  //   timetableLink: string;
  //   activitySession: { status: number };
  // }[] = []; //某某校區密集0
  // classroomWeekend: {
  //   type: number;
  //   timetableLink: string;
  //   activitySession: { status: number };
  // }[] = []; //某某校區假日1
  // getCourseData3: {
  //   type: number;
  //   timetableLink: string;
  //   activitySession: { status: number };
  // }[] = []; //用來顯示班別(月份)
  classroomWeekday: course[] = []; // 密集0
  classroomWeekend: course[] = []; // 假日1
  classroomNight: course[] = []; // 夜間2

  classroomList: string[] = []; //顯示select option(不重複)
  // classroomSelect = "臺北校區"; //選擇的classroom
  classroomSelect: {
    //選擇的classroom
    academySessionClassRoom: string;
    academySessionId: number;
    activitySessionId: number;
    activitySessionName: string;
  } = {
    academySessionClassRoom: "",
    academySessionId: 0,
    activitySessionId: 0,
    activitySessionName: "",
  };
  isPrivacyChecked = true;
  isLawChecked = true;
  isWarningShow = false;
  name = "";
  phone = "";
  // sex: string | null = null;
  // age = "";
  sex = "";
  age: number | null = null;
  email = "";
  // sessionInfo: {
  //   name: string;
  //   activityStartTime: string;
  //   activityEndTime: string;
  //   id: number | null;
  // } = { name: "", activityStartTime: "", activityEndTime: "", id: null };
  showClass = true; //預設臺北校區所以打開
  // isWeekday = false;
  // isWeekend = false;
  // showMonth = false;
  classes = [];
  courseClass: course[] = [];
  citySelect: number | null = null;
  selectedDistrict: number | null = null;
  addressStr = "";
  addressDetail = "";
  joinReason = "";
  sourceStr = "";
  sourceDataNotDone = false;
  checkState: boolean[] = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  // emitIndex: number | null;
  isLoading = false;

  //hook
  mounted(): void {
    // this.queryCourseData();
    // this.getType();
    // this.getCourse();
    this.getAvailableClassRoom();
  }

  //methods
  //全部資料
  // async queryCourseData(): Promise<void> {
  //   const res = await tigermaster.database.query("academy_session").get();

  //   //select classroom 挖出開放中的學院
  //   this.getCourseData = this.sortByStart(res.data);

  //   //抽出classroom，並重複的classroom
  //   this.classroomList = Array.from(
  //     new Set(
  //       this.getCourseData
  //         .filter((i) => i.activitySession.status === 1)
  //         .map((d) => d.classroom)
  //     )
  //   );
  // }

  // 取得教室list
  async getAvailableClassRoom(): Promise<void> {
    const res = await tigermaster.activityService.getAvailableClassRoom();
    this.classroomList = res.availableClassRoom;
  }

  async createCourseParticipant(): Promise<void> {
    // const sourceStr = this.sourceSelected.join(",");
    // const sessionIds = this.courseClass.map(
    //   (item: { activitySessionId: number }) => item.activitySessionId
    // );
    const courseIds = this.courseClass.map(
      (item: { academyCourseId: number }) => item.academyCourseId
    );
    const needToCheck = [
      this.name,
      this.phone,
      this.sex,
      this.age,
      this.addressStr,
      this.email,
      this.courseClass[0],
      // this.sessionInfo.id,
      this.sourceStr,
      this.isPrivacyChecked,
      this.isLawChecked,
    ];

    for (let i = 0; i < needToCheck.length; i++) {
      this.checkState[i] = Boolean(needToCheck[i]);
    }

    if (this.checkState.includes(false)) {
      this.isWarningShow = true;
      document.documentElement.scrollTop = 0;
      return;
    }
    
    try {
      this.isLoading = true;

      await tigermaster.activityService.createActivityParticipant({
        name: this.name,
        phone: this.phone,
        sex: this.sex,
        age: this.age !== null ? this.age : 0,
        address: this.addressStr,
        email: this.email,
        courseId: courseIds,
        sessionId: [this.courseClass[0].activitySessionId],
        joinReason: this.joinReason,
        infoSource: this.sourceStr,
        createDate: format(toDate(new Date()), "yyyy-MM-dd HH:mm:ss"),

        // sessionId:this.sessionInfo.id,
        // name:this.name,
        // phone:this.phone,
        // sex:this.sex,
        // age:this.age,
        // address:this.addressStr,
        // joinReason:this.joinReason
      });
    } finally {
      // this.send();
      this.nextPre();
      this.dataToParent();
      this.isLoading = false;
    }
  }
  dataToParent(): void {
    this.$emit("emit-data", {
      // sessionInfo: this.sessionInfo,
      // selectedClassRoom: this.classroomSelect,
      // tableLink: this.getCourseData3[this.emitIndex as number].timetableLink,
      sessionInfo: this.courseClass,
      selectedClassRoom: this.classroomSelect.academySessionClassRoom,
    });
  }
  // async getType(): Promise<void> {
  //   const res2 = await tigermaster.database
  //     .query("academy_session")
  //     .where("academy_session.classroom", "=", this.classroomSelect)
  //     .where("academy_session.type", "IN", [0, 1] as any)
  //     .get();

  //   const getClassroomList = this.sortByStart(res2.data); //某某校區包含密集0或假日1
  //   // this.sortByStart(this.getClassroomList);

  //   this.classroomWeekend = getClassroomList.filter(
  //     (element: {
  //       type: number;
  //       timetableLink: string;
  //       activitySession: { status: number };
  //     }) => {
  //       return element.type === 1 && element.activitySession.status === 1;
  //     }
  //   ); //濾出某某校區包含假日1

  //   this.classroomWeekday = getClassroomList.filter(
  //     (element: {
  //       type: number;
  //       timetableLink: string;
  //       activitySession: { status: number };
  //     }) => {
  //       return element.type === 0 && element.activitySession.status === 1;
  //     }
  //   ); //濾出某某校區包含密集0

  //   this.isWeekend = Boolean(this.classroomWeekend.length);
  //   this.isWeekday = Boolean(this.classroomWeekday.length);
  // }
  // sortByStart(item: academy[]): academy[] {
  //   return item.sort(function (a, b) {
  //     return (
  //       parseInt(
  //         a.activitySession.activityStartTime.substr(5, 2) +
  //           a.activitySession.activityStartTime.substr(8, 2)
  //       ) -
  //       parseInt(
  //         b.activitySession.activityStartTime.substr(5, 2) +
  //           b.activitySession.activityStartTime.substr(8, 2)
  //       )
  //     );
  //   });
  // }

  // async getCourse(): Promise<void> {
  //   try {
  //     const res2 = await tigermaster.database
  //       .query("academy_course")
  //       .orderBy("academy_course.course_date", "desc")
  //       .get();
  //     this.getCourseList = res2.data;
  //     console.log("抓到資料", this.getCourseList);
  //   } catch (error) {
  //     console.error("查詢出錯:", error);
  //     console.log("額外的錯誤信息:", error.message); // 添加這行
  //   }
  // }

  async getCourse(): Promise<void> {
    const res = await tigermaster.activityService.getClassRoomDetail(
      this.classroomSelect.academySessionId,
      this.classroomSelect.activitySessionId
    );
    this.getCourseList = res.classRoomDetailList;
    this.classroomWeekday = this.getCourseList.filter(
      (item: course) => item.academySessionType === 0
    );

    this.classroomWeekend = this.getCourseList.filter(
      (item: course) => item.academySessionType === 1
    );

    this.classroomNight = this.getCourseList.filter(
      (item: course) => item.academySessionType === 2
    );
  }
  //切日期字串
  // checkDate(data: string): string {
  //   return data.substr(0, 10);
  // }
  checkDate(data: string): string {
    const date = parseISO(data);
    const dayNames = ["日", "一", "二", "三", "四", "五", "六"];
    const dayName = dayNames[getDay(date)];
    return `${format(date, "yyyy/MM/dd")}(${dayName})`;
  }
  validator(index: number, val: string | boolean): void {
    switch (index) {
      case 1:
        this.checkState[1] = validateTWPhoneNum(val as string);
        break;

      case 3:
        this.checkState[3] = validateAge(val as string);
        break;

      case 5:
        this.checkState[5] = validateEmail(val as string);
        break;

      default:
        console.log("default");
        this.checkState[index] = Boolean(val);
    }
  }

  //computed
  get cities(): string[] {
    return this.postData.map((item: any) => {
      return item.name;
    });
  }
  get districts(): { name: string; zip: string }[] {
    if (this.citySelect === null) return [];

    return this.postData[this.citySelect].districts;
  }
  // get classrooms(): string[] {
  //   //把開放中的某某學院這項拿出來
  //   return this.getCourseData.map((item: academy) => {
  //     return item.activitySession.status === 1 ? item.classroom : "";
  //   });
  // }

  // watch
  @Watch("citySelect")
  watchCitySelect(): void {
    this.selectedDistrict = null;

    if (
      (this.selectedDistrict as unknown as number) >=
      this.postData[this.citySelect as number].districts.length
    ) {
      this.selectedDistrict = 0;
    }

    if (
      [this.citySelect, this.selectedDistrict, this.addressDetail].includes(
        null
      )
    )
      return;
    this.checkState[4] = true;
  }
  @Watch("selectedDistrict")
  watchSelectedDistrict(): void {
    if (
      [this.citySelect, this.selectedDistrict, this.addressDetail].includes(
        null
      )
    )
      return;
    this.checkState[4] = true;
  }
  @Watch("addressDetail")
  watchAddressDetail(): void {
    if (
      [this.citySelect, this.selectedDistrict, this.addressDetail].includes(
        null
      )
    )
      return;
    this.checkState[4] = true;
    this.addressStr = `${this.cities[this.citySelect as number]}${
      this.districts[this.selectedDistrict as number].name
    }${this.addressDetail}`;
  }
  @Watch("classroomSelect")
  watchClassroomSelect(): void {
    this.showClass = true;
    // this.showMonth = false;

    this.courseClass = [];
    // this.getCourseData3 = [];
    //   this.sessionInfo = {
    //     name: "",
    //     activityStartTime: "",
    //     activityEndTime: "",
    //     id: null,
    //   };
    if (this.classroomSelect) {
      // this.getType();
      this.getCourse();
    }
  }

  @Watch("courseClass")
  sessionInfoWatch(): void {
    // 如果班別課程選了，那教室一定也選了，把7的state-error class移除
    this.checkState[6] = true;
  }

  // @Watch("courseClass")
  // watchCourseClass(): void {
  //   this.getCourseData3 = Number(this.courseClass[0])
  //     ? this.classroomWeekend
  //     : this.classroomWeekday;

  //   console.log("Line574==========>", this.courseClass.length);
  //   console.log("Line575==========>", this.getCourseData3);
  //   if (this.courseClass.length > 0) {
  //     // if(this.getCourseData3.length > 0){
  //     this.showMonth = true;
  //   }
  // }
  // @Watch("sessionInfo")
  // sessionInfoWatch(): void {
  //   // 如果月份課程選了，那班別、教室一定也選了，把7的state-error class移除
  //   this.checkState[6] = true;
  // }
  // @Watch("sourceSelected")
  // watchSourceSelected(): void {
  //   this.checkState[3] = this.sourceSelected.length > 7;
  // }
}
