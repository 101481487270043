
import { Component, Prop, Vue } from "vue-property-decorator";
import { format, parseISO, getDay } from "date-fns";

declare type courseDetail = {
  period: string;
  detailName: string;
  teacher?: string;
};

declare type course = {
  academyCourseId: number;
  academySessionType: number;
  activitySessionLocation: string;
  academySessionId: number;
  activitySessionStartTime: string;
  academySessionCourseName: string;
  activitySessionId: number;
  courseDetail: courseDetail[];
};

@Component({
  components: {},
})
export default class CourseSignUpFinish extends Vue {
  @Prop() public back!: any;
  @Prop() private dataFromChild2!: string;
  // @Prop() private dataFromChild!: {
  //   name: string;
  //   activityStartTime: string;
  //   activityEndTime: string;
  //   id: number | null;
  // };
  @Prop() private dataFromChild!: course[];

  // 切日期、時間字串
  formatTime(period: string): string {
    const timePart = period.split(" ")[1];
    return timePart;
  }
  checkDate(data: string): string {
    const date = parseISO(data);
    const dayNames = ["日", "一", "二", "三", "四", "五", "六"];
    const dayName = dayNames[getDay(date)];
    return `${format(date, "yyyy/MM/dd")}(${dayName})`;
  }
  // 班別
  getSessionTypeLabel(type: number): string {
    switch (type) {
      case 0:
        return "密集";
      case 1:
        return "假日";
      case 2:
        return "夜間";
      default:
        return "";
    }
  }
}
